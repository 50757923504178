.arrow-prev-button,
.arrow-next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 10;
}

.arrow-prev-button {
  left: 10px;
}

.arrow-next-button {
  right: 10px;
}
